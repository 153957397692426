<template>
  <div>
    <div id="jumbotron" class="row text-center p-5">
        <h2 class="display-6 fw-bold">HP Elevation Systems</h2>
        <div class="row justify-content-center ">
              <div class="col-xl-6 fs-4">Here we specialize in providing top quality elevated access systems and services. 
                HP Elevation Systems' primary business is elevated access solutions with safety as our primary concern. We are dedicated professionals with 
                extensive experience that are ready to work for you!</div>
        </div>
        <div class="row justify-content-center">
            <div class="col-xl-3">
                <router-link to="/contact" class="btn btn-royal btn-lg">Hire Us Now!</router-link>
            </div>
        </div>
    </div>

    <div id="content" class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-xl-3 py-5 text-center">
                <h2 class="display-5">Services</h2>
            </div>
        </div>

        <div class="row pt-4">
            <div class="col-xl-3" v-animate-onscroll="'animated slideInLeft'">
                <div class="col-xl-12 text-center">
                    <h2>Supported Scaffold Systems</h2>
                </div>


                <div class="col-xl-12 text-center">
                    <img class="circle my-3" src="../assets/images/SupportedScaffoldSystem.jpg" height="300">
                </div>


                <div class="col-xl-12 py-2 text-center">
                    From Frame and Brace to Pin-lock Systems, we can build the right scaffold system for you. A scaffold tower is a piece 
                    of equipment used for accessing a height in construction, maintenance, inspection, decoration, and more.
                    <br><router-link to="/services#general-scaffold">Read More</router-link>
                </div>
            </div>

            <div class="col-xl-3" v-animate-onscroll="'animated slideInLeft'">
                <div class="col-xl-12 text-center">
                    <h2>Scaffold Dance Floors</h2>
                </div>


                <div class="col-xl-12 text-center">
                    <img class="circle my-3" src="../assets/images/DanceFloor.jpg" height="300">
                </div>


                <div class="col-xl-12 py-2 text-center">
                    Need access hard-to-reach elevated areas, such as the ceilings of a massive cathedral
                    or hotel lobby, dance floor scaffolding provides safe ceiling access? Unlike stair tower scaffolding, dance floor scaffolding can accommodate
                    multiple workers.
                    <br><router-link to="/services#dance-floors">Read More</router-link>
                </div>
            </div>

            <div class="col-xl-3" v-animate-onscroll="'animated slideInLeft'">
                <div class="col-xl-12 text-center">
                    <h2>Debris/Trash Chutes</h2>
                </div>

                <div class="col-xl-12 text-center">
                    <img class="circle my-3" src="../assets/images/home-trash_chute.jpg" height="300">
                </div>

                <div class="col-xl-12 py-2 text-center">
                    We also provide Debris/trash chutes which are apparatuses designed for cleaning and removal large quantities of debris or trash. 
                    Thus speeding up productivity and creating a clean, compliance and safe work environment.
                    <br><router-link to="/services#debris-chutes">Read More</router-link>
                </div>
            </div>

            <div class="col-xl-3" v-animate-onscroll="'animated slideInRight'">
                <div class="col-xl-12 text-center">
                    <h2>Stair Towers</h2>
                </div>


                <div class="col-xl-12 text-center">
                    <img class="circle my-3" src="../assets/images/stair_towers.jpg" height="300">
                </div>


                <div class="col-xl-12 py-2 text-center">
                    We build Stair Towers which are a temporary access solution that provides a replacement option to ladders for safer access and
                    egress on sites, It can be used independently or can be
                    integrated within another scaffold configurations. 
                    <br><router-link to="/services#stair-towers">Read More</router-link>
                </div>
            </div>

            <div class="col-xl-3 mt-5" v-animate-onscroll="'animated slideInRight'">
                <div class="col-xl-12 text-center">
                    <h2>Overhead Protection</h2>
                </div>
                <div class="col-xl-12 text-center">
                    <img class="circle my-3" src="../assets/images/overhead_protection_2.jpg" height="300">
                </div>

                <div class="col-xl-12 py-2 text-center">
                    Overhead protection systems are place to protect pedestrians from hoisted materials and falling debris. "Sidewalk Bridge" or "Sidewalk Shed" are other terms
                    used when describing this system.
                    <br><router-link class="" to="/services#overhead-pro">Read More</router-link>
                </div>
            </div>

            <div class="col-xl-3 mt-5" v-animate-onscroll="'animated slideInRight'">
                <div class="col-xl-12 text-center">
                    <h2>Debris Catch Pans</h2>
                </div>
                <div class="col-xl-12 text-center">
                    <img class="circle my-3" src="../assets/images/catch_pan.jpg" height="300">
                </div>


                <div class="col-xl-12 py-2 text-center">
                    Debris catch pans are used to "catch" debris that may arise during the demolition of elevated work areas. Catch pan systems provide
                    a safe means of access for the personnel performing the work. Allowing safe, and compliant work pratices
                    <br><router-link class="" to="/services#catch-pans">Read More</router-link>
                </div>
            </div>

            <div class="col-xl-3 mt-5" v-animate-onscroll="'animated slideInRight'">
                <div class="col-xl-12 text-center">
                    <h2>Structural Shoring</h2>
                </div>
                <div class="col-xl-12 text-center">
                    <img class="circle my-3" src="../assets/images/Shoring_Picture_1.jpg" height="300">
                </div>

                <div class="col-xl-12 py-2 text-center">
                    Structural shoring is used to temporarily support/sustain the weight of a building or structure during repairs, alterations or when in danger of
                    collapse.
                    <br><router-link class="" to="/services#shoring">Read More</router-link>
                </div>
            </div>

            <div class="col-xl-3 mt-5" v-animate-onscroll="'animated slideInRight'">
                <div class="col-xl-12 text-center">
                    <h2>Wall Bracing</h2>
                </div>
                <div class="col-xl-12 text-center">
                    <img class="circle my-3" src="../assets/images/wall_bracing_2.jpg" height="300">
                </div>


                <div class="col-xl-12 py-2 text-center">
                    Wall bracing provides racking resistance against horizontal (lateral) racking loads from existing structural or environmental conditions and
                    prevents the walls from shifting during repairs, alterations or when in danger of collapse.
                    <br><router-link class="" to="/services#bracing">Read More</router-link>
                </div>
            </div>
        </div>

    </div>
  </div>
  
</template>

<script>
export default {
  name: 'HomeView',
}
   
</script>

<style scoped>
#jumbotron {
    height: 600px;
    color: white;
    background-image: url("../assets/images/scaffold-in-gold-hour.jpeg");
    background-color: grey;
    background-size:cover;
    background-blend-mode: multiply;
    background-position-y: center;
}

#jumbotron h1, #jumbotron h2 {
    color:white;
}

#content a {
    color: #212377;
    text-decoration: none;
    font-weight: bold;
}

#content a:hover {
    color: #3739c8;
    text-decoration: underline;
    font-weight: bold;
}

a.router-link {
    color: white;
    background-color: #212377;
    border: 1px solid #212377;
}
a.router-link:hover {
    color: #212377;
    background-color: white;
    border: 1px solid #212377;
}

</style>
