<template>
  <!-- Footer -->
  <footer class="text-center text-lg-start bg-light text-muted">
    <!-- Section: Social media -->
    <section class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
      <!-- Left -->
      <div class="me-5 d-none d-lg-block">
        <span>Get connected with us on social networks:</span>&nbsp;

        <a href="https://www.facebook.com/profile.php?id=100077892523884" target="_blank"><img src="../assets/images/facebook-icon.png" alt="facebook logo" height="25"/></a>&nbsp;
        <a href="https://www.linkedin.com/company/hp-elevation-systems/" target="_blank"><img src="../assets/images/linkedin-icon.png" alt="linkedin logo" height="25"/></a>&nbsp;
      </div>
      <!-- Left -->
    </section>
    <!-- Section: Social media -->

    <!-- Section: Links  -->
    <section class="">
      <div class="container text-center text-md-start mt-5">
        <!-- Grid row -->
        <div class="row mt-3">
          <!-- Grid column -->
          <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
            <!-- Content -->
            <h6 class="text-uppercase fw-bold mb-4">
              HP Elevation Systems
            </h6>
            <p>
             We are Elevated Access Solutions and Shoring Solutions with safety as our primary concern.
            </p>
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
            <!-- Links -->
            <h6 class="text-uppercase fw-bold mb-4">
              Services
            </h6>
            <p>
              <a href="" class="text-reset">Supported Scaffold Systems</a>
            </p>
            <p>
              <a href="" class="text-reset">Scaffold Dance Floors</a>
            </p>
            <p>
              <a href="" class="text-reset">Debris/Trash Chutes</a>
            </p>
            <p>
              <a href="" class="text-reset">Stair Towers</a>
            </p>
            <p>
              <a href="" class="text-reset">Overhead Protection</a>
            </p>
            <p>
              <a href="" class="text-reset">Debris Catch Pans</a>
            </p>
            <p>
              <a href="" class="text-reset">Structural Shoring</a>
            </p>
            <p>
              <a href="" class="text-reset">Wall Bracing</a>
            </p>
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
            <!-- Links -->
            <h6 class="text-uppercase fw-bold mb-4">Contact</h6>
            <p>
              <a href="mailto:sales@hpelevationsystems.com">sales@hpelevationsystems.com</a>
            </p>
            <p>+1 (713) 412-5626</p>
            <p>+1 (346) 366-4109</p>
            <p>P.O. Box 40755, Houston TX 77240</p>
          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
      </div>
    </section>
    <!-- Section: Links  -->

    <!-- Copyright -->
    <div id="copyright" class="text-center p-4" style="background-color: #d9d9d9">
      © 2023
      <a class="text-reset fw-bold" href="">hpelevations.com</a>
    </div>
    <!-- Copyright -->
  </footer>
</template>

<style scoped>
h1, h2, h3, h4, h5, h6 {
  text-align: left;
}

footer a:link {
  text-decoration: none;
}
footer a:hover {
  text-decoration: underline;
}
footer a:link, footer a:hover, footer a:visited {
  color: #212377;
}
</style>