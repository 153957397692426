<template>
  <div id="content">
    <div class="container-fluid">
      <div id="jumbotron" class="row text-center p-5">
          <h2 class="display-6 fw-bold">Services</h2>
          <div class="row justify-content-center ">
            <div class="col-xl-6 fs-4">We provide a number of services ranging from Supported Scaffold Systems, Scaffold Dance Floors, Debris Trash Chutes, 
              Stair Towers, Overhead Protection, Debris Catch Pans and Structural Shoring &amp; Wall Bracing. Check down below for more detailed descriptions on each service and what we provide.
            </div>
          </div>
          <div class="row justify-content-center">
              <div class="col-xl-3">
                <router-link to="/contact" class="btn btn-royal btn-lg">Hire Us Now!</router-link>
              </div>
          </div>
      </div>
    </div>

    <div class="container">
      <h1 class="py-5">Services Provided</h1>
      <div id="general-scaffold" class="row pt-5" v-animate-onscroll="'animated slideInLeft'">
        <h2 class="col-xl-3">Supported Scaffold System</h2>
        <div class="col-xl-7">
          A scaffold tower is a piece of equipment used for accessing a height in construction, maintenance, inspection, decoration,
          and more. Scaffold towers are made up of frames, bars and platforms. They come in separate parts so that they can be erected and dismantled on
          site. They are available in a range of heights and with different work platform sizes to suit various applications.
        </div>
      </div>  
  
 


      <div id="pin-lock" class="row" v-animate-onscroll="'animated slideInLeft'">
        <div class="col-xl-3">
          <h4>Pin-lock System</h4>
          <img class="square float-end" src="../assets/images/pin_lock_system_scaffold_1.jpg">
        </div>

        <div class="col-xl-7">
          <p>
          With parts that are considered modular, the ability to create complex configurations such as those found in power plants or boilers is possible. 
          System scaffolding offers solutions for applications with higher levels of complexity and industrial, marine and offshore environments.
          </p>
          <p>
            The parts that used to make up this system consist of vertical standards and ledgers/bearers. With a quick tap of the hammer, the horizontal
            members can be locked in with either a wedge or a steel bonnet to hold positioning. Several sizes are available.
          </p>
          <p>
            Just as frame scaffolding offers a variety of accessories, this system offers accessories needed to accommodate your scaffolding needs.
            One such accessory is stair units, available in 30” - 36” wide, accommodating double egress, making multiple personnel access easier.
          </p>
        </div>
      </div>

      <div id="frame-brace" class="row pt-3" v-animate-onscroll="'animated slideInRight'">
        <div class="col-xl-3">
          <h4>Frame and Brace</h4>
          <img class="square m-2 float-end" src="../assets/images/frame_and_brace_1.jpg">
        </div>

        <div class="col-xl-7">
          <p>
            Need a system with more flexibility? Frame and brace scaffolding offer a variety of options to
            fit your needs from frame sizes varying from 3’ - 5’ in width and 2’ - 6’4” in height to cross
            bracing with standard sizes and custom sizes allowing more accommodating measures for any
            project. Guardrail panels and systems are available; ensuring the safety of workers. Decking is
            available in wood, steel and aluminum options. Adjustable entry gates and access ladders allow
            for easy access. Should you need the scaffold system to be mobile or hold higher capacity, we
            have hard rubber casters available in 5” and 8” sizes and our 12” casters boast a 3,000-pound capacity.
          </p>
        </div>
      </div>

      <div id="dance-floors" class="row pt-5" v-animate-onscroll="'animated slideInRight'">
        <h2 class="col-xl-3">Scaffold Dance Floors</h2>
        <p class="col-xl-7">
          For building projects that require workers to access hard-to-reach elevated areas, such as the ceilings of a massive cathedral
          or hotel lobby, dance floor scaffolding provides safe ceiling access. Unlike stair tower scaffolding, dance floor scaffolding can accommodate
          multiple workers. This means a large-scale ceiling renovation or repair project can be completed in a shorter time frame since multiple workers can
          access the ceiling at the same time.
        </p>
        <div class="col-xl-12 text-center">
          <img class="square m-2" src="../assets/images/dance_floor_1.jpg" height="300">
          <img class="square m-2" src="../assets/images/dance_floor_2.jpg" height="300">
        </div>   
      </div>  

      <div id="debris-chutes" class="row pt-5" v-animate-onscroll="'animated slideInRight'">
        <h2 class="col-xl-3">Debris/Trash Chutes</h2>
        <div class="col-xl-7">
            <p>
            Debris chutes are apparatuses designed for cleaning large quantities of debris or trash from roofs and
            multi-level buildings. Construction debris chutes help speed up the clean-up process and dispose of items that can
            obstruct workers’ and materials’ smooth movement. Consequently, unneeded items can be removed to protect workers against
            injuring themselves. Productivity will undoubtedly be enhanced.
            </p>

            <p>
              This debris removal method is most relied on by roofers, builders and masons because
              it is the quickest, safest and more efficient. The phrases debris chutes and construction
              trash chutes are used based on the installation placement. Regardless, the installation
              process and uses are regulated by the Occupational Safety and Health Administration
              (OSHA).
            </p>

            <p>
              The chutes must be installed to streamline the disposal process and be secured to the
              building to ensure the safety of workers on-site, passerbys and surrounding properties.
              The disposal of debris, trash or waste materials from at least 20 feet of height is required
              to use a debris chute per OSHA’s regulations and mandates. This is to prevent debris or
              trash of considerable weight from being removed by workers via elevators or stairways,
              causing health risks and stench. OSHA outlines regulations to be followed in the install
              process to guarantee safety. As a result, an experienced company must be hired to install
              the appropriate chutes to the build to facility debris disposal. 
            </p>
            
        </div>
        
        <div class="col-xl-12 text-center">
            <img class="square m-2" src="../assets/images/trash_chute_1.jpg" height="300">
            <img class="square" src="../assets/images/trash_chute_2.jpg" height="300">
        </div>
      </div>

      <div id="stair-towers" class="row pt-5" v-animate-onscroll="'animated slideInLeft'">
        <h2 class="col-xl-3">Stair Towers</h2>
        <p class="col-xl-7">
          Stair towers are a temporary access solution that provides a replacement option to ladders for safer access and
          egress on sites. This scaffold option also reduces the dangers of human error of falling materials. It can be used independently or can be
          integrated within another scaffold configuration. Stair tower scaffolds feature standardized stairs, power load-bearing columns and secure
          railings, fencing in the system.
        </p>
        <div class="col-xl-12 text-center">
          <img class="square m-2" src="../assets/images/stair_tower_1.jpg" height="300">
          <img class="square m-2" src="../assets/images/stair_tower_2.jpg" height="300">
          <img class="square m-2" src="../assets/images/stair_tower_3.jpg" height="300">
        </div>       
      </div>   

      <div id="overhead-pro" class="row pt-5" v-animate-onscroll="'animated slideInLeft'">
        <h2 class="col-xl-3">Overhead Protection</h2>
        <p class="col-xl-7">
          Overhead protection systems are place to protect pedestrians from hoisted materials and falling debris. "Sidewalk Bridge" or "Sidewalk Shed" are other terms
          used when describing this system. Overhead protection systems need to be put in place befoer beginning new construction, demolitlion of an existing structure
          or inspection or renovation of a building facade. 
        </p>
        <div class="col-xl-12 text-center">
            <img class="square m-2" src="../assets/images/overhead_protection_1.jpg" height="300">
            <img class="square m-2" src="../assets/images/overhead_protection_2.jpg" height="300">
            <img class="square" src="../assets/images/overhead_protection_3.jpg" height="300">
        </div>
      </div>  

      <div id="catch-pans" class="row py-5" v-animate-onscroll="'animated slideInRight'">
        <h2 class="col-xl-3">Debris Catch Pans</h2>
        <p class="col-xl-7">
          Debris catch pans are commonly used during the demolition of elevated work areas. Catch pan systems provide
          a safe means of access for the personnel performing the work, but are also used to control combustible and non-combustible waste
          material resulting from the demolition of structures
        </p>
        <div class="col-xl-12 text-center">
            <img class="square m-2" src="../assets/images/catch_pan_brace.jpg" height="300">
            <img class="square m-2" src="../assets/images/catch_pan_building.jpg" height="300">
            <img class="square" src="../assets/images/catch_pan.jpg" height="300">
        </div>
      </div>  

      <div id="shoring" class="row pt-5" v-animate-onscroll="'animated slideInLeft'">
        <h2 class="col-xl-3">Structural Shoring</h2>
        <p class="col-xl-7">
Structural shoring is used to temporarily support/sustain the weight of a building or structure during repairs, alterations or when in danger of collapse. 
Shoring systems, when installed, typically require engineering to establish load calculations specific to the application. 
        </p>
        <div class="col-xl-12 text-center">
            <img class="square m-2" src="../assets/images/Shoring_Picture_1.jpg" height="300">
            <img class="square m-2" src="../assets/images/Shoring_Picture_2.jpg" height="300">
            <img class="square" src="../assets/images/Shoring_Picture_3.jpg" height="300">
            <img class="square" src="../assets/images/Shoring_Picture_4.jpg" height="300">
        </div>
      </div>  

      <div id="bracing" class="row py-5" v-animate-onscroll="'animated slideInRight'">
        <h2 class="col-xl-3">Wall Bracing</h2>
        <p class="col-xl-7">
          Wall bracing provides racking resistance against horizontal (lateral) racking loads from existing structural or environmental conditions and prevents 
          the walls from shifting during repairs, alterations or when in danger of collapse. Wall bracing, when installed, typically requires engineering to 
          establish load calculations specific to the application.
        </p>
        <div class="col-xl-12 text-center">
            <img class="square m-2" src="../assets/images/wall_bracing_1.png" height="300">
            <img class="square m-2" src="../assets/images/wall_bracing_2.jpg" height="300">
            <img class="square" src="../assets/images/wall_bracing_3.jpg" height="300">
            <img class="square" src="../assets/images/wall_bracing_4.jpg" height="300">
        </div>
      </div>  
    </div>
  </div>
  
</template>

<script>

export default {
  name: 'ServicesView',
}
</script>

<style scoped>
#jumbotron {
  background-image: url("../assets/images/pexels-scaffold-starkov.jpg");
  background-size: cover;
  background-position: 55% 75%;
  background-repeat: no-repeat;
}

h4 {
  text-align: right;
}
.img-right {
  text-align: right;;
}

.square {
  width: 200px;
  height: 200px;
  border: 3px solid #212377;
}

</style>