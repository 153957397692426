<template>
  <the-navigation></the-navigation>
  <router-view v-slot="slotProps">
    <transition name="route" mode="out-in">
      <component :is="slotProps.Component"></component>
    </transition>
  </router-view>
  <the-footer></the-footer>
</template>

<script>
import TheNavigation from '@/components/TheNavigation.vue'
import TheFooter from '@/components/TheFooter.vue'

export default {
  components: {
    TheNavigation,
    TheFooter
  },
}
</script>

<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  line-height: 1.75;
}

#jumbotron {
    height: 600px;
    color: white;
    background-color: grey;
    background-size:cover;
    background-blend-mode: multiply;
    background-position-y: center;
}

#jumbotron h1, #jumbotron h2 {
    color:white;
}


.route-enter-from {
  opacity: 0;
  transform: translateX(-30px);
}
.route-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
.route-enter-active {
  transition: all 0.3s ease-out;
}
.route-leave-active {
  transition: all 0.3s ease-in;
}
.route-enter-to,
route-leave-from {
  opacity: 1;
  transform: translateX(0);
}

h1, h2, h3, h4, h5, h6 {
  text-align: center;
  color: #212377;
}


.bg-l-gray {
    background-color: #d9d9d9;
    border-bottom: 3px solid #212377;
}



/* Content */


.btn-royal {
    color: white;
    background-color: #212377;
    border: 1px solid #212377;
}

.btn-royal:hover {
    color: #212377;
    background-color: white;
    border: 1px solid #212377;
}

.circle {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 3px solid #212377;
}

.royal-font {
  color:#212377;
  font-weight: bold;
}

.gray-font {
  color:4d4d4d;
  font-weight: bold;
}

</style>
