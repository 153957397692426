<template>
  <div id="content">
    <base-dialog :show="!!error" title="An Error Occurred" @close="handleError">
      <p>{{ error }}</p>
    </base-dialog>

    <div class="container-fluid">
      <div id="jumbotron" class="row text-center p-5">
          <h2 class="display-6 fw-bold">Contact Us</h2>
          <div class="row justify-content-center ">
            <div class="col-xl-6 fs-4">
              Get in touch with an HP Elevation System’s Professional. If you have any questions, comments or would like a quote, feel free to send us a message or 
              give us a call.
            </div>
          </div>
          <div class="row justify-content-center ">
            <div class="col-xl-6 display-6">
                <a href="tel:7134125626">+1 (713) 412-5626</a> | <a href="tel:3463664109">+1 (346) 366-4109</a>
            </div>
          </div>
      </div>
    </div>



    <div class="container">
      <h1 class="py-5">Send Us a Message</h1>

      <form @submit.prevent class="row g-3 mb-5" id="contact">
        <div class="col-xl-4">
          <img id="contact-img" src="../assets/images/contact_us_edited.jpg" v-animate-onscroll="'animated slideInLeft'">
        </div>
        
        <div class="col-xl-6">

          <div v-if="isLoading" class="alert alert-primary">
            Sending Message!<base-spinner></base-spinner>
          </div>
          <div v-if="success" class="alert alert-success">
            Message sent! Thanks, an Elevation Professional will be in contact soon.
          </div>

          <div class="row">
            <div class="col-xl-6" :class="{invalid: !name.isValid}">
              <label for="name" class="form-label">Name&nbsp;<span class="royal-font">*</span></label>
              <input type="text" class="form-control" id="name" v-model.trim="name.val" @keyup="clearValidity('name')">
              <div v-if="!name.isValid" class="helptext">Name cannot be empty.</div>
            </div>
            <div class="col-xl-6" :class="{invalid: !company.isValid}">
              <label for="company" class="form-label">Company</label>
              <input type="text" class="form-control" id="company" v-model.trim="company.val" @keyup="clearValidity('company')">
              <div v-if="!company.isValid" class="helptext">Company cannot be empty.</div>
            </div>
            <div class="col-xl-6" :class="{invalid: !phone.isValid}">
              <label for="phoneNumber" class="form-label">Phone&nbsp;<span class="royal-font">*</span></label>
              <input type="text" class="form-control" id="phoneNumber" placeholder="333-222-5555" v-model.trim="phone.val" @keyup="clearValidity('phone')">
              <div v-if="!phone.isValid" class="helptext">Phone cannot be empty.</div>
            </div>
            <div class="col-xl-6" :class="{invalid: !email.isValid}">
              <label for="email" class="form-label">Email&nbsp;<span class="royal-font">*</span></label>
              <input type="email" class="form-control" id="email" v-model.trim="email.val" @keyup="clearValidity('email')">
              <div v-if="!email.isValid" class="helptext">Email cannot be empty.</div>
            </div>

            <div class="col-xl-12 pt-3" >
              <fieldset class="field-set" :class="{invalid: !services.isValid}">
                <legend>Services</legend>
                <div>
                  <input type="checkbox" value="debris_chutes" id="debrish_chutes" name="debris_chutes" v-model.trim="services.val" @blur="clearValidity('services')" />
                  <label for="debris_chutes">Debris/Trash Chutes</label>
                </div>
                <div>
                  <input type="checkbox" value="stair_towers" id="stair_towers" name="stair_towers" v-model.trim="services.val" @blur="clearValidity('services')" />
                  <label for="stair_towers">Stair Towers</label>
                </div>
                <div>
                  <input type="checkbox" value="dance_floors" id="dance_floors" name="dance_floors" v-model.trim="services.val" @blur="clearValidity('services')" />
                  <label for="dance_floors">Scaffold Dance Floors</label>
                </div>
                <div>
                  <input type="checkbox" value="general" id="general" name="general" v-model.trim="services.val" @blur="clearValidity('services')" />
                  <label for="general">General Scaffolding</label>
                </div>
                <div>
                  <input type="checkbox" value="catch_pans" id="catch_pans" name="catch_pans" v-model.trim="services.val" @blur="clearValidity('services')" />
                  <label for="catch_pans">Debrish Catch Pans</label>
                </div>
                <div>
                  <input type="checkbox" value="overhead" id="overhead" name="overhead"  v-model.trim="services.val" @blur="clearValidity('services')" />
                  <label for="overhead">Overhead Protection</label>
                </div>
                <div>
                  <input type="checkbox" value="shoring" id="shoring" name="shoring" v-model.trim="services.val" @blur="clearValidity('services')" />
                  <label for="shoring">Structural Shoring</label>
                </div>
                <div>
                  <input type="checkbox" value="bracing" id="bracing" name="bracing"  v-model.trim="services.val" @blur="clearValidity('services')" />
                  <label for="bracing">Wall Bracing</label>
                </div>
              </fieldset>
              <div v-if="!services.isValid" class="helptext">At least one service must be selected</div>
            </div>

            <div :class="{invalid: !message.isValid}">
              <div class="col-xl-12">
                <label for="message" class="form-label">Message&nbsp;<span class="royal-font">*</span></label>
                <textarea id="message" class="form-control" rows="6" v-model.trim="message.val" @keyup="clearValidity('message')"></textarea>
                <div v-if="!message.isValid" class="helptext">Message cannot be empty.</div>
              </div>

              <div class="col-12">
                <button type="submit" class="btn btn-royal mt-3" @click="submitForm">Send Message</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import BaseSpinner from '../components/BaseSpinner.vue'
import BaseDialog from '../components/BaseDialog.vue'

export default {
  name: 'ContactView',
  components: {
    BaseSpinner,
    BaseDialog
  },
  data() {
    return { 
      isLoading: false,
      success: false,
      error: '',
      name: {
        val: '',
        isValid: true
      },
      company: {
        val: '',
        isValid: true
      },
      phone: {
        val: '',
        isValid: true
      },
      email: {
        val: '',
        isValid: true
      },
      services: {
        val: [],
        isValid: true
      },
      message: {
        val: '',
        isValid: true
      },
      formIsValid: true
    }
  },
  methods: {
    clearValidity(input) {
      if(this[input].val === '') {
        this[input].isValid = false;
      } else {

        this[input].isValid = true;
      }
    },
    validateForm() {
      this.formIsValid = true
      if (this.name.val === '') {
        this.name.isValid = false
        this.formIsValid = false
      }
      if (this.company.val === '') {
        this.company.isValid = false
        this.formIsValid = false
      }
      if (this.phone.val === '') {
        this.phone.isValid = false
        this.formIsValid = false
      }
      if (this.email.val === '') {
        this.email.isValid = false
        this.formIsValid = false
      }
      if (this.services.val.length === 0) {
        this.services.isValid = false
        this.formIsValid = false
      }
      if (this.message.val === '') {
        this.message.isValid = false
        this.formIsValid = false
      }
    },
    async submitForm() {
      this.validateForm()

      if (!this.formIsValid) {
        return
      }

      try {
        let endpoint = '/api/contact/'
        this.isLoading = true
        await this.axios.post(endpoint, {
          name: this.name.val,
          company: this.company.val,
          phone: this.phone.val,
          email: this.email.val,
          services: this.services.val,
          message: this.message.val,
        })
        this.success = true
      } catch(error) {
        console.log(error.response)
        this.error = error.response.status + ' ' + error.response.statusText + ' ' + error.response.data
      }
      this.isLoading = false
    },
    handleError() {
        this.error = null
    }
  }
}
</script>

<style scoped>
a {
  color: #d9d9d9;
  text-decoration: none;
}
#jumbotron {
  background-image: url("../assets/images/scaffold-in-gold-hour.jpeg");
  background-size: cover;
  background-position: 55% 25%;
  background-repeat: no-repeat;
}
#contact-img {
  height:350px;
  width: 350px;
  border: 3px solid #212377;
  border-radius: 50%;
}

select, input, textarea {
  border: 1px solid #212377;
}
.field-set {
  border: 1px solid #212377;
}
fieldset, legend {
  all: revert;
}

h4 {
  text-align: left;
}
.invalid input,
.invalid textarea,
.invalid.field-set {
  border: 1px solid red;
}

.helptext {
  color: red;
  font-size: .8em;
  margin-bottom: 1px;
}
</style>