<template>
  <div id="content">
    <div class="container-fluid">
      <div id="jumbotron" class="row text-center p-5">
          
          <div class="row justify-content-center">
            <div class="col-xl-6" style="display:flex;justify-content:center;align-items:center;">
              <h2 class="display-5 fw-bold text-center">About Us</h2>
            </div>
          </div>
      </div>
    </div>


    <div class="container">
      <div class="row">
        <h1 class="py-5">Who We Are</h1>
      </div>
      
      <div class="row">
        <div class="col-xl-4">
          <img id="contact-img" src="../assets/images/pexels-handshake.jpg" v-animate-onscroll="'animated slideInLeft'">
        </div>
        
        <div class="col-xl-6 mb-5">
          <div class="row g-3">
            <div class="col-xl-12 mb-3" v-animate-onscroll="'animated slideInLeft'">
                <span class="royal-font">HP Elevations System’s</span> primary business is elevated access and shoring solutions with safety as our primary concern. 
            </div>
            <div class="col-xl-12" v-animate-onscroll="'animated slideInRight'">
                <span class="royal-font">HP Elevations System’s</span> ownership consists of 2 partners that have
                more than 40 years’ combined experience working in the safety industry. We have tracked countless hours performing the following safety services:

              <ul class="pt-3">
                <li>Developing Written Scaffold Safety Programs</li>
                <li>Personnel safety training that includes Scaffold Erector, Scaffold User and Competent Person Designation. </li>
                <li>Comprehensive safety assessments of manufactured and engineered scaffold systems. </li>
              </ul>
            </div>
            <div class="col-xl-12" v-animate-onscroll="'animated slideInLeft'">
                <span class="gray-font">Site Supervision:</span> Our site supervisors shall obtain an OSHA 30 Hour Construction, Scaffold Erector & User, Fall Protection Equipment User, 1st Aid, CPR & AED 
                certification and are designated as the Competent Person for their worksites.
            </div>
            <div class="col-xl-12" v-animate-onscroll="'animated slideInRight'">
              <span class="gray-font">Field Personnel:</span> Our field personnel shall obtain an OSHA 10 Hour Construction, Scaffold Erector & User, Fall Protection Equipment User and training specific to 
              the scope of work as required. This may include, but not limited to Rough Terrain Forklift Operator, Aerial Boom & Scissor Lift Operator, Rigging & Signal 
              Communication, Confined Space Entry, etc.
            </div>
            <div class="col-xl-12" v-animate-onscroll="'animated slideInLeft'">
               <span class="royal-font">HP Elevation System’s</span> field operations are led by our general superintendent that has over 25 years’ experience in the scaffold industry. This includes, but is 
              not limited to erecting/ dismantling supported scaffolding (Frame, Tube & Clamp, SafLock Systems, etc.), stair towers, overhead protection and suspended 
              scaffolding.  
            </div>
          </div>       
        </div>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: 'AboutView',
}
</script>

<style scoped>
#jumbotron {
  background-image: url("../assets/images/pexels-scaffold-sorenson.jpg");
  background-size: cover;
}
#contact-img {
  height:350px;
  width: 350px;
  border: 3px solid #212377;
  border-radius: 50%;
}
</style>
